<template>
  <v-container fluid>
    <headerVue :title="$t('menu.cargaantivirus')" />
    <v-row>
      <v-col cols="12">
        <v-card
          ><v-row>
            <v-col cols="1" /><v-col cols="3">{{ $t("selection") }}</v-col>
            <v-col cols="4"> 
             <!-- <v-file-input accept="file/*" />-->
              <input type="file" @change="onFileSelected" />
              </v-col>
              <v-col cols="12"
              ><a href="/layout/antivirus.xlsx">{{ $t("antivirusLayout") }}</a></v-col
            ></v-row
          >
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" align-self="center"
        ><v-btn @click="backToMenu">{{ $t("back") }}</v-btn> <v-btn color="primary" @click="save"> {{ $t("save") }} </v-btn></v-col
      >
    </v-row>
  </v-container>
</template>
<script>
import headerVue from "../../components/header.vue"
export default {
  components: {
    headerVue,
  },
  data() {
    return {
      description: "",
    }
  },
  methods: {
     onFileSelected(event)
    {
        // pass file data for the axios post request
        this.selectedFile = event.target.files[0];
       
       
    },
    backToMenu() {
      this.$router.push("/")
    },
    save() {
       // file upload logic here
      const formData = new FormData();
      formData.append("archivo", this.selectedFile, this.selectedFile.name);
      console.log(this.datos);
     const json = JSON.stringify(this.datos);
     // ruta producción https://consubanco.azurewebsites.net
      axios
        .post("https://consubanco.azurewebsites.net/api/Cargas/usuario", formData ,{
       
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "multipart/form-data"
          },
          
        })
        .then((response) => {
          this.model = response.data.modelo
          alert("Se subio el archivo con éxito");
        })
        .catch((error) => {
          alert("Revisando en subida excel error: " + error)
        });
      /*axios
        .post(Server + "/tipodisco, {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        })
        .then((response) => {
          this.model = response.data.modelo
        })
        .catch((error) => {
          alert("error: " + error)
        })*/
      this.$router.push("/carga-completa")
    },
  },
  created() {},
}
</script>